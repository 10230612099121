import { generatePath } from 'react-router-dom';
import { omit, without } from 'lodash';
import qs from 'qs';
import { snakeCase } from '@tripledotstudios/react-core';

import {
  get, post, put, destroy, postMultipart, putMultipart, patch,
} from '@requests/common';
import camelCase from '@services/camelCase';
import underscore from 'snakecase-keys';

export const modelPaths = {};

const cleanUpParams = (path, data) => omit(
  data,
  [...path.matchAll(/:(\w+)/g)].map((obj) => obj[1]),
);

const stringifyQuery = (params) => {
  if (!params) return null;

  return qs.stringify(underscore(params), {
    arrayFormat: 'brackets',
    encode: true,
  });
};

const actions = {
  get: (path) => (data, options) => get(generatePath(path, data), {
    ...options,
    params: cleanUpParams(path, data),
  }),
  post: (path) => (data, options) => post(generatePath(path, data), cleanUpParams(path, data), options),
  postMultipart: (path) => (data, options) => postMultipart(generatePath(path, data),
    cleanUpParams(path, data),
    options),
  putMultipart: (path) => (data, options) => putMultipart(generatePath(path, data), cleanUpParams(path, data), options),
  put: (path) => (data, options) => put(generatePath(path, data), cleanUpParams(path, data), options),
  patch: (path) => (data, options) => patch(generatePath(path, data), cleanUpParams(path, data), options),
  delete: (path) => (data) => destroy(generatePath(path, data), cleanUpParams(path, data)),
};

const addAction = (rootPath, action, actionPath = null, requestType = 'get', apiRootPath = null) => {
  const buildPath = (path) => (actionPath ? `${path}/${actionPath}` : path);
  const path = buildPath(rootPath);
  const apiPath = `/api${buildPath(apiRootPath || rootPath)}`;

  return {
    [`${action}RawPath`]: path,
    [`${action}Path`]: ({ routePostfix, query, ...data } = {}) => {
      const basePath = generatePath(path, data);
      const fullPath = routePostfix ? `${basePath} /${routePostfix}` : basePath;
      const queryString = stringifyQuery(query);
      return queryString ? `${fullPath}?${queryString}` : fullPath;
    },
    [`${action}Request`]: actions[requestType](apiPath),
  };
};

const ignoredModuleSegments = ['', 'admin', 'applications', ':applicationId'];

const resources = (baseUrl, entityName, options = {}) => {
  const rootPath = `${baseUrl}/${entityName}`;
  const addActionIfAllowed = (action, actionPath = null, requestType = 'get') => (
    (options.except && options.except.includes(action)) || (options.only && !options.only.includes(action))
      ? {}
      : addAction(rootPath, action, actionPath, requestType, options.apiRootPath)
  );

  const routesSet = {
    ...addActionIfAllowed('index'),
    ...addActionIfAllowed('show', ':id'),
    ...addActionIfAllowed('new', 'new'),
    ...addActionIfAllowed('edit', ':id/edit'),
    ...addActionIfAllowed('create', null, 'post'),
    ...addActionIfAllowed('update', ':id', 'put'),
    ...addActionIfAllowed('delete', ':id', 'delete'),
    ...(options.overrides ? options.overrides(options.apiRootPath || rootPath) : {}),
  };

  let model;
  if (options.model) {
    model = options.model;
  } else {
    const modules = options.module ? [options.module] : without(baseUrl.split('/'), ...ignoredModuleSegments);
    const entitySegments = entityName.split('/');
    model = [...modules, ...entitySegments].map(camelCase).join('::').replace(/s$/, '');
  }
  modelPaths[model] = routesSet;

  return routesSet;
};

const duplicateAction = (rootPath, apiRootPath = null) => addAction(
  rootPath, 'duplicate', ':id/duplicate', 'post', apiRootPath,
);
const downloadAction = (rootPath, apiRootPath = null) => addAction(
  rootPath, 'download', ':id/download', 'get', apiRootPath,
);
const uploadAction = (rootPath, apiRootPath = null) => addAction(
  rootPath, 'upload', 'upload', 'postMultipart', apiRootPath,
);
const publishAction = (rootPath) => addAction(rootPath, 'publish', 'publish', 'post');

const RailsCoreRoutes = addAction('/admin', 'theme', 'theme', 'patch');

const basePath = '/admin/applications/:applicationId';

//
// Applications Routes
//
const ApplicationRoutes = resources('/admin', 'applications', {
  except: ['delete'],
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'getTypes', 'types', 'get'),
    ...addAction(rootPath, 'create', null, 'postMultipart'),
    ...addAction(rootPath, 'update', ':id', 'putMultipart'),
  }),
});

//
// Home Routes
//
const HomeRoutes = addAction('/admin/home', 'index');

//
// Level Sets Routes
//
const LevelSetRoutes = {
  Variants: resources(basePath, 'level_set_variants', {
    overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
  }),
  Sets: resources(basePath, 'level_sets', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
    }),
  }),
};

//
// Blackjack routes
//

const blackjackBasePath = `${basePath}/blackjack`;
const BlackjackRoutes = {
  DailyChallengeConfigs: resources(blackjackBasePath, 'daily_challenge_configs', {
    model: 'Blackjack::DailyChallengeConfig',
  }),
  TableConfigs: resources(blackjackBasePath, 'table_configs', {
    model: 'Blackjack::TableConfig',
  }),
};

//
// Solitaire routes
//

const solitaireBasePath = `${basePath}/solitaire`;
const SolitaireRoutes = {
  LevelLayouts: resources(solitaireBasePath, 'level_layouts'),
  Journeys: {
    EventConfigs: {
      ...resources(solitaireBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(solitaireBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Sol routes
//

const solBasePath = `${basePath}/sol`;
const SolRoutes = {
  PlayerLevelSets: resources(solBasePath, 'player_levels/sets', { overrides: duplicateAction }),
  WinSets: resources(solBasePath, 'win_sets/sets', {
    model: 'Sol::WinSets::Set',
    overrides: duplicateAction,
  }),
  FirstTryStreakConfigs: resources(solBasePath, 'first_try_streak_configs/sets', {
    model: 'Sol::FirstTryStreakConfigs::Set',
    overrides: duplicateAction,
  }),
  CategorySets: resources(solBasePath, 'category_sets', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
};

//
// Sudoku routes
//

const SudokuRoutes = {
  Journeys: {
    EventConfigs: {
      ...resources(`${basePath}/sudoku`, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Chapter based routes
//
const uiAssetModels = ['UiThemes', 'FigurePacks', 'Terrains'];
const generateChapterBasedRoutes = (gameType, options = {}) => {
  const gameNamespace = snakeCase(gameType) === 'woodoku_saga' ? 'discovery' : snakeCase(gameType);
  const chapterBasedBasePath = `/admin/applications/:applicationId/${gameNamespace}`;
  return {
    Banks: resources(chapterBasedBasePath, 'banks', {
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...downloadAction(rootPath),
        ...uploadAction(rootPath),
        ...addAction(rootPath, 'bulkDownload', 'bulk_download'),
      }),
      ...options,
    }),
    Chapters: addAction(chapterBasedBasePath, 'new', 'chapters/new', 'get'),
    ChapterSets: resources(chapterBasedBasePath, 'chapter_sets', {
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'copyToChapterSetOptions', 'copy_to_chapter_set_options'),
        ...addAction(rootPath, 'bulkDuplicateChapters', ':id/bulk_duplicate_chapters', 'post'),
      }),
      ...options,
    }),
    DifficultySchemas: resources(chapterBasedBasePath, 'difficulty_schemas', {
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'difficultyMatrix', 'difficulty_matrix'),
      }),
      ...options,
    }),
    LevelLayouts: resources(chapterBasedBasePath, 'level_layouts', {
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'bulkDuplicate', 'bulk_duplicate', 'post'),
        ...addAction(rootPath, 'downloadCsv', 'download_csv', 'get'),
        ...addAction(rootPath, 'uploadCsv', 'upload_csv', 'postMultipart'),
        ...addAction(rootPath, 'levelEditor', ':levelId/level_editor', 'get'),
      }),
      ...options,
    }),
    UiConfigs: resources(chapterBasedBasePath, 'ui_configs', {
      overrides: (rootPath) => duplicateAction(rootPath),
      ...options,
    }),
    ...uiAssetModels.reduce(
      (routes, model) => ({
        ...routes,
        [model]: resources(chapterBasedBasePath, snakeCase(model), {
          overrides: (rootPath) => duplicateAction(rootPath),
          ...options,
        }),
      }),
      {},
    ),
  };
};

//
// Match3d Routes
//
const match3dBasePath = `${basePath}/match3d`;
const Match3dRoutes = {
  PersonalLevelSets: resources(match3dBasePath, 'personal_level_sets', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
  ...generateChapterBasedRoutes('match3d'),
};

//
// WoodokuSaga Routes
//
const woodokuSagaBasePath = `${basePath}/discovery`;
const woodokuSagaAbilitiesPath = `${woodokuSagaBasePath}/abilities`;
const WoodokuSagaRoutes = {
  ...generateChapterBasedRoutes('discovery', { module: 'WoodokuSaga' }),
  FigureSets: resources(woodokuSagaBasePath, 'figure_sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
    module: 'WoodokuSaga',
  }),
  AreaSets: resources(woodokuSagaBasePath, 'area_sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
    module: 'WoodokuSaga',
  }),
  LevelRewardSets: resources(woodokuSagaBasePath, 'level_reward_sets', {
    model: 'WoodokuSaga::LevelRewards::Set',
    overrides: (rootPath) => duplicateAction(rootPath),
    module: 'WoodokuSaga',
  }),
  Abilities: {
    Names: resources(woodokuSagaAbilitiesPath, 'names', {
      overrides: (rootPath) => duplicateAction(rootPath),
      module: 'WoodokuSaga::Abilities',
    }),
    Impacts: resources(woodokuSagaAbilitiesPath, 'impacts', {
      overrides: (rootPath) => duplicateAction(rootPath),
      module: 'WoodokuSaga::Abilities',
    }),
    Sets: resources(woodokuSagaAbilitiesPath, 'sets', {
      overrides: (rootPath) => duplicateAction(rootPath),
      module: 'WoodokuSaga::Abilities',
    }),
  },
  MagicPots: resources(woodokuSagaBasePath, 'magic_pots', {
    overrides: (rootPath) => duplicateAction(rootPath),
    module: 'WoodokuSaga',
  }),
};

//
// Merge Routes
//
const mergeBasePath = `${basePath}/merge`;
const mergeBaseEventsPath = `${mergeBasePath}/events`;
const MergeRoutes = {
  Products: resources(mergeBasePath, 'products', {
    except: ['delete'],
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...publishAction(rootPath),
    }),
  }),

  ProductCatalogs: resources(mergeBasePath, 'product_catalogs', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  InventoryConfigurations: resources(mergeBasePath, 'inventory_configurations', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  StartingGrids: resources(mergeBasePath, 'starting_grids', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  BundleCatalogs: resources(mergeBasePath, 'bundle_catalogs', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  PostcardSets: resources(mergeBasePath, 'postcard_sets', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'buildPostcardSetPostcards', 'build_postcard_set_postcards', 'get'),
    }),
  }),

  PostcardConfigurations: resources(mergeBasePath, 'postcard_configurations', {
    except: ['create', 'update'],
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'buildStoryOrders', 'build_story_orders', 'get'),
      ...addAction(rootPath, 'create', null, 'postMultipart'),
      ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    }),
  }),

  ExpertConfigurations: resources(mergeBasePath, 'expert_configurations', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),

  ExpertSets: resources(mergeBasePath, 'expert_sets', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'buildExpertSetExperts', 'build_expert_set_experts', 'get'),
    }),
  }),

  ItemConfigurationSets: resources(mergeBasePath, 'item_configuration_sets', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'buildItems', 'build_items', 'get'),
    }),
  }),

  LevelSets: resources(mergeBasePath, 'level_sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  UnbubblingConfigurations: resources(mergeBasePath, 'unbubbling_configurations', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  WorldSchemas: resources(mergeBasePath, 'world_schemas', {
    except: ['new', 'create'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'forUser', 'for_user', 'get'),
      ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    }),
  }),

  Worlds: resources(mergeBasePath, 'worlds', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),

  Contacts: resources(`${basePath}/merge`, 'contacts'),

  CutscenesConfigurations: resources(mergeBasePath, 'cutscenes_configurations', {
    except: ['create', 'update'],
    model: 'Merge::Cutscenes::Configuration',
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'postMultipart'),
      ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    }),
  }),

  Events: {
    Schemas: resources(mergeBaseEventsPath, 'schemas', {
      except: ['new', 'create'],
    }),
    OrdersConfigurations: resources(mergeBaseEventsPath, 'orders_configurations', {
      overrides: (rootPath) => duplicateAction(rootPath),
    }),
    LevelSets: resources(mergeBaseEventsPath, 'level_sets', {
      overrides: (rootPath) => duplicateAction(rootPath),
    }),
  },

  Journeys: {
    EventConfigs: {
      ...resources(mergeBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Woodoku Routes
//
const woodokuBasePath = `${basePath}/woodoku`;
const WoodokuRoutes = {
  TileTypes: resources(woodokuBasePath, 'tile_types'),
  DailyChallenges: {
    EventConfigs: resources(woodokuBasePath, 'daily_challenges/event_configs', {
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
        ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
      }),
    }),
  },
  TriggerConditions: resources(woodokuBasePath, 'trigger_conditions/sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  PerfectFit: resources(woodokuBasePath, 'perfect_fit/sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  DynamicConfigs: resources(woodokuBasePath, 'dynamic_configs', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  ShapesBankSets: resources(woodokuBasePath, 'shapes_bank_sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  FigureSetDynamicConfigs: resources(woodokuBasePath, 'figure_set_dynamic_configs', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  Journeys: {
    EventConfigs: {
      ...resources(woodokuBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(woodokuBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  PromoJourneys: {
    EventConfigs: {
      ...resources(woodokuBasePath, 'journeys/promo_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  EndOfJourneyContent: {
    EventConfigs: {
      ...resources(woodokuBasePath, 'journeys/end_of_journey_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  HardJourneys: resources(woodokuBasePath, 'hard_journeys', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  XpLevelConfigs: resources(woodokuBasePath, 'xp_level_configs', {
    except: ['create', 'update'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'postMultipart'),
      ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    }),
  }),
  BonusLevels: {
    Events: resources(woodokuBasePath, 'bonus_levels/events', {
      model: 'Woodoku::BonusLevels::Event',
      only: [],
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'instance'),
        ...addAction(rootPath, 'update', null, 'post'),
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      }),
    }),
    EventVariants: resources(woodokuBasePath, 'bonus_levels/event_variants', {
      only: [],
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
      }),
    }),
    LevelBanks: resources(woodokuBasePath, 'bonus_levels/level_banks', {
      model: 'Woodoku::BonusLevels::LevelBanks::Bank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
      }),
    }),
  },
};

//
// Figure Sets Routes
//
const figureSetsBasePath = `${basePath}/figure_sets`;
const FigureSetsRoutes = {
  Sets: resources(figureSetsBasePath, 'sets', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  Shapes: resources(figureSetsBasePath, 'shapes'),
};

//
// Block Party Routes
//
const blockPartyBasePath = `${basePath}/block_party`;
const BlockPartyRoutes = {
  DailyChallenges: {
    EventConfigs: resources(blockPartyBasePath, 'daily_challenges/event_configs', {
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
        ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
      }),
    }),
  },
  Journeys: {
    EventConfigs: {
      ...resources(blockPartyBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(blockPartyBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Sortaga Routes
//
const sortagaBasePath = `${basePath}/sortaga`;
const SortagaRoutes = {
  DailyChallenges: {
    EventConfigs: resources(sortagaBasePath, 'daily_challenges/event_configs', {
      overrides: (rootPath) => ({
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
        ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
      }),
    }),
  },
  Journeys: {
    EventConfigs: {
      ...resources(sortagaBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(sortagaBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Hexago Routes
//
const hexagoBasePath = `${basePath}/hexago`;
const HexagoRoutes = {
  ...generateChapterBasedRoutes('hexago'),
  Journeys: {
    EventConfigs: {
      ...resources(hexagoBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(hexagoBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Taos Routes
//
const taosBasePath = `${basePath}/taos`;
const TaosRoutes = {
  ...generateChapterBasedRoutes('taos'),
  Journeys: {
    EventConfigs: {
      ...resources(taosBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  ActivationJourneys: {
    EventConfigs: {
      ...resources(taosBasePath, 'journeys/activation_event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
};

//
// Loray Routes
//
const LorayRoutes = {
  ...generateChapterBasedRoutes('loray'),
};

//
// Axum Routes
//
const AxumRoutes = {};

//
// Lusk Routes
//
const LuskRoutes = {};

//
// Voss Routes
//
const VossRoutes = {};

//
// Payments Routes
//
const paymentsBasePath = `${basePath}/payments`;
const paymentCatalogsVariantsBasePath = `${basePath}/payments`;
const PaymentsRoutes = {
  StoreProducts: resources(paymentsBasePath, 'store_products', {
    except: ['delete'],
  }),
  Catalogs: resources(paymentsBasePath, 'catalogs', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
    }),
  }),
  CatalogVariants: resources(paymentCatalogsVariantsBasePath, 'catalog_variants', {
    overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
  }),
  ProductImages: resources(paymentsBasePath, 'product_images', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  Products: resources(paymentsBasePath, 'products', {
    except: ['delete'],
    overrides: (rootPath) => ({
      ...publishAction(rootPath),
      ...duplicateAction(rootPath),
    }),
  }),
};

//
// Game Settings Routes
//
const gameSettingsBasePath = `${basePath}/:gameType`;

const GameSettingsRoutes = {
  ...resources(gameSettingsBasePath, 'game_settings', {
    model: 'GameSettings',
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'base', 'base', 'get'),
      ...addAction(rootPath, 'fieldNames', 'field_names', 'get'),
      ...addAction(rootPath, 'searchField', 'search_field', 'post'),
      ...addAction(rootPath, 'toggleStatus', ':id/toggle_status', 'post'),
      ...duplicateAction(rootPath),
    }),
  }),
};

const archivedSettingsBasePath = `${basePath}/game_settings`;

const ArchivedSettingsRoutes = resources(
  archivedSettingsBasePath,
  'archived_settings',
  { model: 'GameSettings::ArchivedSetting' },
);

//
// Files
//
const FilesRoutes = resources(basePath, 'files', {
  model: 'Files::BaseFile',
  except: ['create', 'update'],
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'create', null, 'postMultipart'),
    ...addAction(rootPath, 'update', ':id', 'putMultipart'),
  }),
});

//
// Items Comparison
//
const ItemsComparisonRoutes = resources(basePath, 'comparison', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'compareEntities', 'compare_entities', 'get'),
  }),
});

//
// App User
//
const appUserNestedResourceBasePath = `${basePath}/app_users/:appUserId`;
const AppUserRoutes = {
  ...resources(`${basePath}/users`, 'app_users', {
    apiRootPath: `${basePath}/app_users`,
    model: 'Accounts::AppUser',
    only: ['edit', 'update'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'payments', ':id/payments'),
      ...addAction(rootPath, 'cloudsave', ':id/cloudsave'),
      ...addAction(rootPath, 'copyCloudsave', ':id/copy_cloudsave', 'post'),
      ...addAction(rootPath, 'updateCloudsave', ':id/update_cloudsave', 'putMultipart'),
      ...addAction(rootPath, 'socialNetworkAccounts', ':id/social_network_accounts'),
      ...addAction(rootPath, 'anonymisationNotificationStatuses', ':id/anonymisation_notification_statuses'),
      ...addAction(rootPath, 'unlinkSocialProfile', ':id/unlink_social_profile', 'post'),
      ...addAction(rootPath, 'anonymise', ':id/anonymise', 'post'),
    }),
  }),
  UserRewards: resources(appUserNestedResourceBasePath, 'user_rewards', {
    only: ['index', 'create', 'delete'],
    model: 'Rewards::UserReward',
  }),
};

//
// App Events
//
const AppEventsRoutes = resources(basePath, 'app_events');

//
// Spider solitaire
//
const spiderSolitaireBasePath = `${basePath}/spider_solitaire`;
const SpiderSolitaireRoutes = {
  WorldStatsConfig: resources(spiderSolitaireBasePath, 'daily_challenges/world_stats_configs', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  PlayerLevelSets: resources(spiderSolitaireBasePath, 'player_levels/sets'),
};

//
// Devices
//
const DeviceRoutes = resources(
  basePath,
  'devices',
  {
    model: 'Accounts::Device',
    apiRootPath: '/admin/devices',
  },
);

//
// Tester Devices
//
const TesterDeviceRoutes = resources(
  basePath,
  'tester_devices',
  {
    model: 'Accounts::TesterDevice',
    apiRootPath: '/admin/tester_devices',
  },
);

//
// Favourites
//
const FavouritesRoutes = resources(basePath, 'favourites', {
  model: 'Favorites',
  apiRootPath: '/admin/favourites',
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'forAdminUser', ':id/for_admin_user', 'get'),
  }),
},
);

//
// Missions
//
const missionsBasePath = `${basePath}/missions`;
const MissionsRoutes = {
  AllocationInstances: resources(missionsBasePath, 'allocation_instances', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'regenerate', ':id/regenerate', 'post'),
    }),
  }),
  AllocationTemplates: resources(missionsBasePath, 'allocation_templates', {
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  MissionTypes: resources(missionsBasePath, 'mission_types', {
    overrides: (rootPath) => addAction(rootPath, 'archive', ':id/archive', 'patch'),
  }),
  Rewards: {
    Types: resources(missionsBasePath, 'rewards/types'),
    Sets: resources(missionsBasePath, 'rewards/sets', {
      overrides: (rootPath) => duplicateAction(rootPath),
    }),
  },
  Settings: resources(missionsBasePath, 'settings', {
    model: 'Missions::Settings',
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'edit', 'edit', 'get'),
      ...addAction(rootPath, 'update', null, 'put'),
      ...addAction(rootPath, 'generateAllocations', 'generate_allocations', 'post'),
    }),
  }),
};

//
// Journey routes
//
const journeysBasePath = `${basePath}/journeys`;
const JourneyRoutes = {
  Events: resources(journeysBasePath, 'events', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  ActivationEvents: resources(journeysBasePath, 'activation_events', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  EndOfJourneyEvents: resources(journeysBasePath, 'end_of_journey_events', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  PromoEvents: resources(journeysBasePath, 'promo_events', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  EventConfigs: resources(journeysBasePath, 'event_config'),
  LevelBanks: resources(journeysBasePath, 'level_banks', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
    model: 'Journeys::LevelBanks::Bank',
  }),
};

//
// Daily Challenge Routes
//
const dailyChallengesBasePath = `${basePath}/daily_challenges`;
const DailyChallengesRoutes = {
  Events: resources(dailyChallengesBasePath, 'events', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  LevelBanks: resources(dailyChallengesBasePath, 'level_banks', {
    model: 'DailyChallenges::LevelBanks::Bank',
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
};

//
// Ads Routes
//
const adsBasePath = `${basePath}/ads`;
const AdsRoutes = {
  Configurations: resources(adsBasePath, 'configurations', {
    model: 'Ads::Configuration',
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'base', 'base', 'get'),
      ...addAction(rootPath, 'toggleStatus', ':id/toggle_status', 'post'),
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
      ...addAction(rootPath, 'fieldNames', 'field_names', 'get'),
      ...addAction(rootPath, 'searchField', 'search_field', 'post'),
    }),
  }),
  AdUnitCampaigns: resources(adsBasePath, 'ad_unit_campaigns', {
    model: 'Ads::AdUnitCampaign',
    except: ['show'],
  }),
  Interstitials: {
    Placements: resources(adsBasePath, 'interstitials/placements', {
      model: 'Ads::Interstitials::Placements::Placement',
    }),
    PlacementBanks: resources(adsBasePath, 'interstitials/placement_banks', {
      model: 'Ads::Interstitials::PlacementBank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
      }),
    }),
  },
  RewardedVideos: {
    Placements: resources(adsBasePath, 'rewarded_videos/placements', {
      model: 'Ads::RewardedVideos::Placements::Base',
    }),
    PlacementBanks: resources(adsBasePath, 'rewarded_videos/placement_banks', {
      model: 'Ads::RewardedVideos::PlacementBank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
      }),
    }),
  },
  Badges: {
    Placements: resources(adsBasePath, 'badges/placements', {
      model: 'Ads::Badges::Placements::Placement',
    }),
    PlacementBanks: resources(adsBasePath, 'badges/placement_banks', {
      model: 'Ads::Badges::PlacementBank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
      }),
    }),
  },
};

//
// Cross Promo Applications
//
const CrossPromoApplicationsRoutes = resources(basePath, 'cross_promo_applications', {
  only: 'index',
});

//
// Admin Users Routes
//
const AdminUsersRoutes = resources('/admin', 'admin_users', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'generateInternalToken', ':id/generate_internal_token', 'put'),
  }),
});

//
// Admin Routes
//
const settingsRootPath = '/admin/settings';
const systemSettingsRootPath = `${settingsRootPath}/system`;
const adminSettingsRootPath = `${settingsRootPath}/admin`;
const adminApplicationSettingsRootPath = `${adminSettingsRootPath}/applications/:applicationId`;
const testerSettingsRootPath = `${settingsRootPath}/tester`;

const AdminRoutes = {
  ...addAction('/admin', 'profile', 'profile'),
  ...addAction('/admin', 'settings', 'settings'),
  Settings: {
    Admin: {
      ...addAction(adminSettingsRootPath, 'edit', 'edit', 'get'),
      ...addAction(adminSettingsRootPath, 'update', '', 'put'),

      Applications: {
        ...addAction(adminApplicationSettingsRootPath, 'edit', 'edit', 'get'),
        ...addAction(adminApplicationSettingsRootPath, 'update', '', 'put'),
      },
    },
    Tester: {
      ...addAction(testerSettingsRootPath, 'edit', 'edit', 'get'),
      ...addAction(testerSettingsRootPath, 'update', '', 'put'),
    },
    System: {
      ...addAction(systemSettingsRootPath, 'edit', 'edit', 'get'),
      ...addAction(systemSettingsRootPath, 'update', '', 'put'),
    },
  },
};

const RewardsRoutes = {
  Items: resources(`${basePath}/rewards`, 'items'),
};

//
// Localizations Routes
//
const localizationsBasePath = `${basePath}/localizations`;
const LocalizationsRoutes = {
  Configs: resources(localizationsBasePath, 'configs', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'duplicate', ':id/duplicate', 'post'),
    }),
  }),
  Keys: resources(localizationsBasePath, 'keys'),
  Languages: resources(localizationsBasePath, 'languages'),
};

//
// My Pet Cafe
//
const myPetCafeBasePath = `${basePath}/my_pet_cafe`;
const MyPetCafeRoutes = {
  AppConfigs: resources(myPetCafeBasePath, 'app_configs', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'base', 'base'),
    }),
  }),
  BetUpConfigurations: resources(myPetCafeBasePath, 'bet_up_configurations', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  Journeys: {
    EventConfigs: {
      ...resources(myPetCafeBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
  },
  JsonConfigs: resources(myPetCafeBasePath, 'json_configs', {
    except: ['create', 'update'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'postMultipart'),
      ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    }),
  }),
  LevelManifestSets: resources(myPetCafeBasePath, 'level_manifest_sets', {
    model: 'MyPetCafe::ManifestSet',
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'base', 'base'),
    }),
  }),
  LevelManifestSetTypes: resources(myPetCafeBasePath, 'manifest_set_types'),
  LevelLayouts: resources(myPetCafeBasePath, 'level_layouts', {
    except: ['create', 'update', 'edit', 'new', 'show'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'home', 'home'),
    }),
  }),
  Adventures: {
    Entities: resources(myPetCafeBasePath, 'adventures/entities', {
      model: 'MyPetCafe::Adventures::Entity',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      }),
    }),
    EntityVariants: resources(myPetCafeBasePath, 'adventures/entity_variants', {
      overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
    }),
    EventBanks: resources(myPetCafeBasePath, 'adventures/event_banks', {
      model: 'MyPetCafe::Adventures::Bank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
      }),
    }),
  },
  UnlockRequirements: resources(myPetCafeBasePath, 'unlock_requirements', {
    model: 'MyPetCafe::UnlockRequirements::Configuration',
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
  Leaderboards: {
    Configs: resources(myPetCafeBasePath, 'leaderboards', {
      model: 'MyPetCafe::Leaderboards::Config',
      overrides: (rootPath) => ({ ...duplicateAction(rootPath) }),
    }),
  },
  SoloMissions: {
    Configs: resources(myPetCafeBasePath, 'solo_missions/configs', {
      model: 'MyPetCafe::SoloMissions::Config',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      }),
    }),
    ConfigVariants: resources(myPetCafeBasePath, 'solo_missions/config_variants', {
      overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
    }),
    EventBanks: resources(myPetCafeBasePath, 'solo_missions/event_banks', {
      model: 'MyPetCafe::SoloMissions::EventBank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
      }),
    }),
  },
  WinStreaks: {
    Configs: resources(myPetCafeBasePath, 'win_streaks/configs', {
      model: 'MyPetCafe::WinStreaks::Config',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
        ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      }),
    }),
    ConfigVariants: resources(myPetCafeBasePath, 'win_streaks/config_variants', {
      overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
    }),
    EventBanks: resources(myPetCafeBasePath, 'win_streaks/event_banks', {
      model: 'MyPetCafe::WinStreaks::EventBank',
      overrides: (rootPath) => ({
        ...duplicateAction(rootPath),
        ...addAction(rootPath, 'bulkDuplicateLevels', ':id/bulk_duplicate_levels', 'post'),
        ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
      }),
    }),
  },
};

//
// Tile Routes
//
const tileBasePath = `${basePath}/tile`;
const TileRoutes = {
  DifficultySchemaSets: resources(tileBasePath, 'difficulty_schema_sets', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  RewardSets: resources(tileBasePath, 'reward_sets', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  ModesConfigs: resources(tileBasePath, 'modes_configs', {
    model: 'Tile::ModesConfigs::Base',
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  CategorySets: resources(tileBasePath, 'category_sets', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
  }),
  AssetBundleSchemaSets: resources(tileBasePath, 'asset_bundle_schema_sets', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
    model: 'Tile::AssetBundleSchemaSets::Set',
  }),
  Journeys: {
    EventConfigs: {
      ...resources(tileBasePath, 'journeys/event_configs', {
        overrides: (rootPath) => ({
          ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
          ...addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
        }),
      }),
    },
    LevelBanks: {
      Levels: resources(tileBasePath, '/journeys/level_banks/levels', {
        only: ['new'],
      }),
    },
  },
  RewardsForWinsSchemas: resources(tileBasePath, 'rewards_for_wins_schemas', {
    except: ['show'],
    overrides: (rootPath) => duplicateAction(rootPath),
    model: 'Tile::RewardsForWins::Schema',
  }),
  ...generateChapterBasedRoutes('tile'),
  ChapterSetImports: resources(basePath, 'tile/chapter_set_imports', {
    except: ['create'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'postMultipart'),
    }),
  }),
  BankBulkImports: resources(basePath, 'tile/bank_bulk_imports', {
    except: ['create', 'new'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'create', null, 'postMultipart'),
    }),
  }),
  LayoutReplacements: resources(basePath, 'tile/layout_replacements', { only: ['create', 'index', 'new'] }),
};

const streaksBasePath = `${basePath}/streaks`;
const StreaksRoutes = {
  Entities: resources(streaksBasePath, 'entities', {
    model: 'Streaks::Entity',
    except: 'delete',
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'experimentScoped', ':id/experiment_scoped/:experimentId'),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
    }),
  }),
  EntityVariants: resources(streaksBasePath, 'entity_variants', {
    overrides: (rootPath) => addAction(rootPath, 'updateVariants', 'update_variants', 'patch'),
  }),
};

//
// Woo::Aspen Routes
//
const wooAspenBasePath = `${basePath}/woo/aspen`;
const WooAspenRoutes = {
  ...generateChapterBasedRoutes('woo/aspen'),
  RewardSets: resources(wooAspenBasePath, 'reward_sets', {
    except: ['show'],
    overrides: duplicateAction,
  }),
  PlayOnPopUps: resources(wooAspenBasePath, 'play_on_pop_ups', {
    except: ['show'],
    overrides: duplicateAction,
  }),
  PurchaseConfigs: resources(wooAspenBasePath, 'purchase_configs', {
    except: ['show'],
    overrides: duplicateAction,
  }),
  LiveOpsTokens: resources(wooAspenBasePath, 'live_ops_tokens', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
};

WooAspenRoutes.Chapters.Pictures = resources(wooAspenBasePath, 'chapter_pictures', {
  except: ['show', 'create', 'update'],
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'create', null, 'postMultipart'),
    ...addAction(rootPath, 'update', ':id', 'putMultipart'),
    ...duplicateAction(rootPath),
  }),
});

//
// Assets
//
const assetsBasePath = `${basePath}/assets`;
const AssetsRoutes = {
  Keys: resources(assetsBasePath, 'keys', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'partialUpdate', ':id/partial_update', 'patch'),
      ...addAction(rootPath, 'massLive', 'mass_live', 'patch'),
      ...duplicateAction(rootPath),
    }),
  }),
  Bundles: {
    ...addAction(`${assetsBasePath}/bundles`, 'validate', 'validate', 'post'),
  },
};

//
// Daily Rewards
//
const dailyRewardsBasePath = `${basePath}/daily_rewards`;
const DailyRewardsRoutes = {
  Banks: resources(dailyRewardsBasePath, 'banks', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'bulkDuplicateDays', ':id/bulk_duplicate_days', 'post'),
    }),
  }),
  Configs: resources(dailyRewardsBasePath, 'configs', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
};

//
// Audit
//
const AuditRoutes = {
  Logs: resources('/admin', 'audit_log', { only: ['index'] }),
  ApplicationLogs: resources(basePath, 'audit_log', { only: ['index'] }),
  Entities: resources('/admin/audit', 'entities', { only: ['index'] }),
  Versions: resources('/admin', 'versions', {
    only: ['show'],
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'latest', 'latest'),
      ...addAction(rootPath, 'list', 'list'),
      ...addAction(rootPath, 'pagination', 'pagination'),
      ...addAction(rootPath, 'association', 'association'),
    }),
  }),
};

//
// Version Updates
//
const versionUpdatesBasePath = `${basePath}/version_updates`;
const VersionUpdateRoutes = {
  AppUpdates: resources(versionUpdatesBasePath, 'app_updates', { overrides: duplicateAction }),
  ForceUpdates: resources(versionUpdatesBasePath, 'force_updates'),
};

//
// Experiments
//
const abTestingBasePath = `${basePath}/ab_testing`;
const AbTestingRoutes = {
  Experiments: resources(abTestingBasePath, 'experiments', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
      ...addAction(rootPath, 'archive', ':id/archive', 'patch'),
      ...addAction(rootPath, 'validateVariantRuleGroup', 'validate_variant_rule_group', 'post'),
      ...addAction(rootPath, 'createVariantRuleGroup', ':id/create_variant_rule_group', 'post'),
      ...addAction(rootPath, 'inUseInRules', ':id/in_use_in_rules', 'get'),
      ...addAction(rootPath, 'entityUsage', 'entity_usage', 'get'),
      ...addAction(rootPath, 'addPropertyToExperiment', ':id/add_property_to_experiment', 'post'),
      ...addAction(rootPath, 'addEntityToExperiment', ':id/add_entity_to_experiment', 'post'),
      ...addAction(rootPath, 'createVariant', ':experimentId/variants', 'post'),
      ...addAction(rootPath, 'destroyVariant', ':experimentId/variants/:id', 'delete'),
    }),
  }),
};

//
// Maintenance mode
//
const MaintenanceModeRoutes = resources('/admin', 'maintenance_modes', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'show', null, 'get'),
  }),
});

//
// Labels
//
const LabelRoutes = resources(basePath, 'labels', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'search', 'search', 'post'),
  }),
});

const flexiblePopupsBasePath = `${basePath}/flexible_popups`;
const FlexiblePopupRoutes = {
  Popups: resources(flexiblePopupsBasePath, 'popups', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
  Actions: resources(flexiblePopupsBasePath, 'actions', {
    overrides: (rootPath) => ({
      ...duplicateAction(rootPath),
    }),
  }),
};

const PlayerSegmentRoutes = resources(basePath, 'player_segments', {
  only: ['index'],
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'forPlayer', 'for_player/:id/:deviceId', 'get'),
  }),
});

//
// Rule Groups
//
const NamedRuleGroupsRoutes = resources(`${basePath}/rules`, 'named_groups', {
  overrides: (rootPath) => ({
    ...duplicateAction(rootPath),
    ...addAction(rootPath, 'validateRuleSections', 'validate_rule_sections', 'post'),
  }),
});

//
// Mass Operations
//
const MassOperationsRoutes = resources(basePath, 'mass_operations', {
  only: ['index'],
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'massUpdate', 'mass_update', 'put'),
  }),
});

//
//
//
const ExternalDataImportRoutes = {
  Artifacts: resources('/admin/external_data/import', 'artifacts', {
    overrides: (rootPath) => ({
      ...addAction(rootPath, 'retry', ':id/retry', 'post'),
    }),
  }),
};

const External = resources('/v4', '', {
  overrides: (rootPath) => ({
    ...addAction(rootPath, 'init', 'init', 'post'),
  }),
});

//
// UI Kit Routes
//
const UIKitRoutes = addAction('/admin/ui_kit', 'index');

//
// Machine Learning Routes
//
const machineLearningBasePath = `${basePath}/machine_learning`;
const MachineLearningRoutes = {
  Models: resources(machineLearningBasePath, 'models', {
    model: 'MachineLearning::Model',
    overrides: duplicateAction,
  }),
};

export {
  RailsCoreRoutes,
  ApplicationRoutes,
  ArchivedSettingsRoutes,
  HomeRoutes,
  AdsRoutes,
  CrossPromoApplicationsRoutes,
  AdminUsersRoutes,
  AppEventsRoutes,
  AppUserRoutes,
  LevelSetRoutes,
  BlackjackRoutes,
  generateChapterBasedRoutes,
  Match3dRoutes,
  MergeRoutes,
  WoodokuRoutes,
  FigureSetsRoutes,
  PaymentsRoutes,
  SolRoutes,
  SolitaireRoutes,
  SpiderSolitaireRoutes,
  TesterDeviceRoutes,
  FavouritesRoutes,
  DeviceRoutes,
  MissionsRoutes,
  JourneyRoutes,
  DailyChallengesRoutes,
  RewardsRoutes,
  DailyRewardsRoutes,
  LocalizationsRoutes,
  MyPetCafeRoutes,
  AssetsRoutes,
  AuditRoutes,
  VersionUpdateRoutes,
  StreaksRoutes,
  AdminRoutes,
  AbTestingRoutes,
  MaintenanceModeRoutes,
  GameSettingsRoutes,
  TileRoutes,
  SudokuRoutes,
  LabelRoutes,
  FlexiblePopupRoutes,
  PlayerSegmentRoutes,
  NamedRuleGroupsRoutes,
  WoodokuSagaRoutes,
  BlockPartyRoutes,
  SortagaRoutes,
  HexagoRoutes,
  WooAspenRoutes,
  TaosRoutes,
  LorayRoutes,
  AxumRoutes,
  LuskRoutes,
  VossRoutes,
  FilesRoutes,
  ExternalDataImportRoutes,
  UIKitRoutes,
  ItemsComparisonRoutes,
  MassOperationsRoutes,
  External,
  MachineLearningRoutes,
};

import React from 'react';
import { useTheme } from 'styled-components';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from '@tripledotstudios/react-core';

import { LocalizationsRoutes } from '@pages/routes';

export default function LocalizationsNavigationSection({ applicationId, minimized = false }) {
  const theme = useTheme();

  return (
    <Sidebar.SectionItem icon={faGlobe} title="Localisations" color={theme.pink} minimized={minimized}>
      <Sidebar.LinkItem
        to={LocalizationsRoutes.Languages.indexPath({ applicationId })}
        title="Languages"
      />
      <Sidebar.LinkItem
        to={LocalizationsRoutes.Keys.indexPath({ applicationId })}
        title="Keys"
      />
      <Sidebar.LinkItem
        to={LocalizationsRoutes.Configs.indexPath({ applicationId })}
        title="Settings Configs"
      />
    </Sidebar.SectionItem>
  );
}

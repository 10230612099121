import APP_DATA from '@services/appData';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const harmonyHost = APP_DATA.configs.harmony.host;

export default function Localizations({ application, theme }) {
  const { publicAppId } = application;

  const buildShorcut = ({ type, title }) => (
    {
      title,
      link: `${harmonyHost}/admin/applications/ls/${publicAppId}/translations/${type}`,
      icon: faGlobe,
      color: theme.pink,
    }
  );

  return [
    buildShorcut({ title: 'Translations (Live)', type: 'live' }),
    buildShorcut({ title: 'Translations (Test)', type: 'test' }),
  ];
}

import { useTheme } from 'styled-components';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';

import { PaymentsRoutes } from '@pages/routes';

import localizationShortcutsBuilder from './localizations';

export default function Blackjack({ application }) {
  const theme = useTheme();
  const { id: applicationId } = application;

  return [
    [
      {
        title: 'Catalogs',
        link: PaymentsRoutes.Catalogs.indexPath({ applicationId }),
        icon: faMoneyBill,
        color: theme.lightGreen,
      },
      {
        title: 'Products',
        link: PaymentsRoutes.Products.indexPath({ applicationId }),
        icon: faMoneyBill,
        color: theme.lightGreen,
      },
      {
        title: 'Store Products',
        link: PaymentsRoutes.StoreProducts.indexPath({ applicationId }),
        icon: faMoneyBill,
        color: theme.lightGreen,
      },
    ],
    [
      ...localizationShortcutsBuilder({ application, theme }),
    ],
  ];
}

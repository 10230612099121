import { useTheme } from 'styled-components';
import { faCode, faBullseye } from '@fortawesome/free-solid-svg-icons';

import { MyPetCafeRoutes, MissionsRoutes } from '@pages/routes';

export default function myPetCafe({ application }) {
  const { id: applicationId } = application;
  const theme = useTheme();
  const jsonConfigBaseIndexPath = MyPetCafeRoutes.JsonConfigs.indexPath({ applicationId });

  return [
    [
      {
        title: 'Remote DB JSON Configs',
        link: `${jsonConfigBaseIndexPath}/remote_db`,
        icon: faCode,
        color: theme.tulipTree,
      },
      {
        title: 'Chapter Configs',
        link: `${jsonConfigBaseIndexPath}/chapter_configs`,
        icon: faCode,
        color: theme.tulipTree,
      },
    ],
    [
      {
        title: 'Mission Allocations',
        link: MissionsRoutes.AllocationTemplates.indexPath({ applicationId }),
        icon: faBullseye,
        color: theme.tulipTree,
      },
    ],
  ];
}

import { useTheme } from 'styled-components';
import { faGlobeAmericas, faNewspaper } from '@fortawesome/free-solid-svg-icons';

import { MergeRoutes, AdsRoutes } from '@pages/routes';

export default function Merge({ application }) {
  const theme = useTheme();
  const { id: applicationId } = application;

  return [
    [
      {
        title: 'World Schemas',
        link: MergeRoutes.WorldSchemas.indexPath({ applicationId }),
        icon: faGlobeAmericas,
        color: theme.purple,
      },
      {
        title: 'Postcard Configurations',
        link: MergeRoutes.PostcardConfigurations.indexPath({ applicationId }),
        icon: faGlobeAmericas,
        color: theme.purple,
      },
      {
        title: 'Expert Configurations',
        link: MergeRoutes.ExpertConfigurations.indexPath({ applicationId }),
        icon: faGlobeAmericas,
        color: theme.purple,
      },
      {
        title: 'Level Sets',
        link: MergeRoutes.LevelSets.indexPath({ applicationId }),
        icon: faGlobeAmericas,
        color: theme.purple,
      },
    ],
    [
      {
        title: 'Ads Configurations',
        link: AdsRoutes.Configurations.indexPath({ applicationId }),
        icon: faNewspaper,
        color: theme.lightPurple,
      },
    ],
  ];
}

import { useTheme } from 'styled-components';
import { faListOl, faCalendarDays, faNewspaper } from '@fortawesome/free-solid-svg-icons';

import { TileRoutes, JourneyRoutes, AdsRoutes } from '@pages/routes';

export default function Tile({ application }) {
  const theme = useTheme();
  const { id: applicationId } = application;

  return [
    [
      {
        title: 'Layout Banks',
        link: TileRoutes.Banks.indexPath({ applicationId }),
        icon: faListOl,
        color: theme.purple,
      },
      {
        title: 'Chapter Sets',
        link: TileRoutes.ChapterSets.indexPath({ applicationId }),
        icon: faListOl,
        color: theme.purple,
      },
      {
        title: 'Modes Configs',
        link: TileRoutes.ModesConfigs.indexPath({ applicationId }),
        icon: faListOl,
        color: theme.purple,
      },
    ],
    [
      {
        title: 'Scrapbooks',
        link: JourneyRoutes.Events.indexPath({ applicationId }),
        icon: faCalendarDays,
        color: theme.yellow,
      },
      {
        title: 'Ads Configurations',
        link: AdsRoutes.Configurations.indexPath({ applicationId }),
        icon: faNewspaper,
        color: theme.lightPurple,
      },
    ],
  ];
}
